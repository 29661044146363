.error {
  .form-control {
    border-color: $red-color;
    background-color: $red-bg;
  }
  .text-error {
    margin-top: 5px;
  }
}
.text-error {
  color: $red-color;
  font-size: 14px;
}

.infoWrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-image: linear-gradient(to top, #2e95e2 30%, #27273c 90%);
  // background: rgb(134,55,123);
  // background: linear-gradient(0deg, rgba(134,55,123,1) 0%, rgba(39,39,60,1) 100%);
}
.navbar {
  padding: 0 1rem;
}
.tsparticles-canvas-el {
  height: 100vh !important;
  @media screen and (max-width:768px) {
    display: none;
  }
}

.basic-info {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 4s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#root {
    overflow: hidden;
    height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #FF6347;
}

.contentWrapper {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: scroll;
  margin: 0 auto;
  padding: 0;
  color:#e1e1e1;;
}
/* width */
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #2e95e2;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.waveWrapper {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -25px;
  top: 0;
  margin: auto;
  z-index: -1;
}

.bgTop {
  z-index: -1;
  opacity: 0.1;
}
.bgMiddle {
  z-index: -2;
  opacity: 0.2;
}
.bgBottom {
  z-index: -3;
  opacity: 0.3;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

.littlewhite {
  color: #eeeeee;
}
.imp_block {
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.modal-trigger:hover {
  cursor: pointer !important;
  color: #757575 !important;
}
// nav {
//   background-color: #86377b !important;
// }

// canvas {
//   display: block;
//   vertical-align: bottom;
// }

.son-list {
  li {
    i {
      width: 25px;
    }
  }
}

// page title
.son-title {
  font-size: 24px;
  overflow: hidden;
  margin-bottom: 25px;
  .son-txt {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      width: 1400px;
      pointer-events: none;
      background-color: rgba(164, 186, 253, 0.3);
    }
  }
}

.company-info {
    .son-date {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .son-des {
      white-space: pre-wrap;
    }
}

.avatar {
  object-fit: cover;
  width: 150px;
  height: 150px;
}