

$ff-body: 'Montserrat';
$main-color: #006b33;
$light-main-color: #48A178;
$light-main-color-2: #0da475;
$second-color: #ff7f00;

$text-color: #213547;
$text-light-color: #7b7b7b;
$body-bg: #f8f8f8;
$red-color: #D24350;
$red-bg :#fce8e8;
$brown-color: #a38080;
$orange-color: #F08633;

$orange-bg: #ffebd8;
$light-green-color: #6dd400;
$green-bg:#E8FEF1;
$light-green-bg:#E2EBD8;
$border-color: #d9d9d9;